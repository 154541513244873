<template>
  <div class="about">
    <section id="top">
      <Header id="header" :scrolled="true" />
    </section>

    <section id="moreInfo" class="moreInfo">
      <div class="content-wrap">
        <h4 class="heading">Cloud Policy Statement</h4>

        <ul>
          <li>
            ⁠Access to these cloud services shall be securely managed to ensure
            that the Appmart’s information is not compromised while
            transferring, processing and/or storing information on the cloud
            platforms.
          </li>
          <li>
            ⁠Subscription to cloud service providers shall be subject to
            Management approval and must be backed by a business case. The Head
            of the IT Department will certify that the cloud services vendor
            will adequately address security, privacy and all other IT
            management requirements.
          </li>
          <li>
            ⁠The use of such services must comply with the Appmart’s existing
            Acceptable Use Policy, BYOD Policy and other applicable policies.
          </li>
          <li>
            Personal cloud service accounts shall not be used for storage,
            processing or exchange of the Appmart’s information.
          </li>
        </ul>
      </div>
    </section>

    <section id="footer">
      <Footer />
    </section>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },

  data() {
    return {
      scrollHeight: 0,
    };
  },

  mounted() {
    window.onscroll = function () {
      setFixedTop();
    };

    // Get the header
    const header = document.getElementById("header");

    // Get the offset position of the navbar
    const sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const setFixedTop = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  },
};
</script>

<style>
.mt {
  margin-top: 0.6rem;
}

.about {
  height: 100%;
}

.footer,
.moreInfo {
  padding: 1rem;
}

.heading {
  text-align: center;
  font-size: 2rem;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.content-wrap {
  margin-top: 2rem;
  text-align: justify;
}

.about::before {
  content: "";
  background-image: url("../assets/images/line-cc.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
  opacity: 0.03;
}
ul > li {
  margin: 10px 0;
}
.moreInfo {
  min-height: 600px;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #moreInfo {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
</style>
