<template>
  <div class="px-3 mt-5">
    <div class="d-block d-lg-flex justify-content-between px-3 my-5">
      <div class="col-12 col-lg-6 mt-5 mt-lg-0">
        <h1 class="mt-4">
          Appmart has positioned as one stop shop for payments and collections
          in Africa and the rest of the world.
        </h1>
        <p class="mt-4 mb-5">
          We have provided a superior financial services platform that is easy
          and convenient to use for all stakeholders in the financial space. The
          enabling environment created by Appmart will ensure business growth
          and sustainability.
        </p>
      </div>

      <div class="col-12 col-lg-6 d-flex justify-content-center">
        <img src="@/assets/images/grid_image.png" alt="appmart office" />
      </div>
    </div>

    <div
      class="d-block d-lg-flex flex-row-reverse justify-content-between px-3 my-5"
    >
      <div class="col-12 col-lg-6 mt-5 mt-lg-5">
        <p class="mt-5">
          Our financial tools have been designed to help emerging businesses,
          corporates, small & medium enterprise and individuals strive in their
          area of interest and remain competitive.
        </p>

        <p class="mt-4">
          Appmart story is a very interesting one. Started business in 2016 by
          providing tailored software solutions to both public and government
          institutions in the area of Human Resource management solutions,
          internally generated Revenue software’s and services. Appmart has
          dominated in these areas by serving over 15 states in the country.
        </p>

        <p class="mt-4">
          In 2018, the company decided to change its focus to pursue its
          long-term dream of becoming a dominate player in the payment service
          industry by obtaining the
          <a
            href="https://www.cbn.gov.ng/Out/2021/CCD/List%20of%20PSPs%20New%20Licence%20Category.pdf"
            target="_blank"
            >Central Bank of Nigeria PSSP license which was granted in November,
            2021</a
          >.
        </p>

        <p class="mt-4">
          This licence has opened a new frontier for Appmart to provide its new
          payment and collections services to numerous customers both within and
          outside Nigeria.
        </p>

        <div class="mt-4">
          <h5>Policy Statement</h5>
          <p>
            AppMart is committed to upholding and enhancing Information
            Security, Business Continuity, and Service Management operations by
            implementing an Integrated Management System (IMS) which is in line
            with ISO standards, and all other applicable regulations and
            guidelines, to meet and exceed the expectations of its stakeholders.
          </p>
        </div>
      </div>

      <div class="col-12 col-lg-6 d-flex justify-content-center">
        <img src="@/assets/images/reception.png" alt="appmart office" />
      </div>
    </div>
  </div>
</template>
<script>
import partners from "../../assets/data/partners.json";
export default {
  name: "Whoweare",
  data() {
    return {
      partners,
    };
  },
};
</script>
<style scoped>
img {
  width: 82%;
  margin: 0 auto;
}
h1 {
  font-weight: bold;
  color: #0b2238;
  line-height: 1.2;
  font-size: 2rem;
}
p {
  font-size: 1rem;
  color: #9da6af;
  text-align: justify;
}
a {
  text-decoration: none;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  h1 {
    font-size: 3.125rem;
  }
}
</style>
