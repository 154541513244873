import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import Privacy from "../views/Privacy.vue";
import CloudPolicy from "../views/CloudPolicy.vue";

import TermsOfUse from "../views/TermsOfServices.vue";
import CookiePolicy from "../views/CookiePolicy.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      guest: true,
    },
  },
  {
    path: "/about",
    name: "About",
    meta: {
      guest: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    meta: {
      guest: true,
    },
    component: Privacy,
  },
  {
    path: "/cloud-policy",
    name: "CloudPolicy",
    meta: {
      guest: true,
    },
    component: CloudPolicy,
  },
  {
    path: "/termsofuse",
    name: "TermsOfUse",
    meta: {
      guest: true,
    },
    component: TermsOfUse,
  },
  {
    path: "/cookiepolicy",
    name: "CookiePolicy",
    meta: {
      guest: true,
    },
    component: CookiePolicy,
  },
  // {
  //   path: "/privacy",
  //   name: "Privacy",
  //   meta: {
  //     guest: true,
  //   },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue"),
  // },
  // {
  //   path: "/termsofuse",
  //   name: "TermOfUse",
  //   meta: {
  //     guest: true,
  //   },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "terms" */ "../views/TermsOfServices.vue"),
  // },
  {
    path: "/contactus",
    name: "ContactUs",
    meta: {
      guest: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactUs.vue"),
  },
  {
    path: "/signup",
    name: "SignUp",
    meta: {
      guest: true,
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/SignUp.vue"),
  },
  {
    path: "/signin",
    name: "SignIn",
    meta: {
      guest: true,
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/SignIn.vue"),
  },
  {
    path: "/verify/:token",
    name: "Verify",
    meta: {
      guest: true,
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/Verify.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    meta: {
      guest: true,
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/ForgotPassword.vue"),
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    props: (route) => ({
      email: route.query.email,
      token: route.params.token,
    }),
    meta: {
      guest: true,
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/ResetPassword.vue"),
  },
  {
    path: "/app",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/dashboard/AppFrame.vue"
      ),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/dashboard/Dashboard.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/change-password",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/dashboard/ChangePassword.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  //  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!localStorage.getItem(process.env.VUE_APP_tokenName)) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
