<template>
  <div class="about">
    <section id="top">
      <Header id="header" :scrolled="true" />
    </section>

    <section id="moreInfo" class="">
      <div class="content-wrap">
        <h4 class="heading">Privacy Policy</h4>
        <p>Last Updated: May, 2022</p>
        <p>
          This Privacy Policy describes your privacy rights regarding how
          AppMart collects, use, store, disclose, and protect your Personal
          Information. It applies to our Web Application, software applications,
          payment platforms and all related sites, services, and tools
          regardless of how you access or use them. This Privacy Policy will
          help you understand how we use your information and our retention of
          it. We respect the privacy of our online visitors and registered users
          (Users) as such we will take reasonable steps to protect your
          information. If you do not understand these policies, kindly contact
          us at support@appmartgroup.com.
        </p>

        <div class="mt">
          <h5>Application</h5>
          <p>
            This Privacy Policy only applies to AppMart services . AppMart has
            no control over the sites displayed or linked from our several
            services. These sites may place their own cookies, plug-ins or other
            files on your computer, collect data or ask you for your personal
            information. We do not control these third party sites and are not
            responsible for their privacy statements, please review the privacy
            statements of these third party sites.
          </p>
        </div>

        <div>
          <h5>Age Restriction</h5>
          <p>
            The service is provided to everyone; however, persons below the age
            of 18 can only use the service with the consent of their parent or
            guardian. By using this service, you agree that you are above 18
            years and where underage, your parents/guardian give consent for
            your use of our service. We do not knowingly collect information
            from children under 18 years. If as a parent or guardian, you become
            aware that your child or ward child has provided us with any
            information without your consent, please contact us through details
            on this Privacy Policy.
          </p>
        </div>
        <div>
          <h5>Personal Information We Collect</h5>
          <p>
            We collect your personal while providing services to you. Here are
            the types of information we gather:
          </p>
          <ul>
            <li>
              Information you give us: To gain full access to our Web
              Application and API services, you must Sign Up for a AppMart
              account. When you Sign Up for an account, we collect Personal
              Information (Your Information) which you voluntarily provide to
              us. Personal Information refers to the details you submit when you
              sign up. The Information we collect include:- Company Name (Full
              name of individuals), Bank Verification Number (BVN), date of
              birth, geolocation, billing details, Email Address, Phone Number,
              RC Number, Contact Address, Users profile pictures, Certificate of
              Incorporation, Company Profile, Memorandum and Article of
              Association, Income Tax Certificate.
            </li>
            <li>
              Automatic information: We automatically collect certain types of
              information when you visit, interact with, or use AppMart
              services, when you download our content, when you open emails or
              click on links in emails from us. Example of such information we
              automatically collect include network and connection information,
              computer and device information, location of your device or
              computer, email addresses and phone numbers used to contact us.
            </li>
            <li>
              Information from other sources: We may retrieve additional details
              about you from third parties and other identification/verification
              services such as your financial institution, payment processor,
              and verification services.
            </li>
          </ul>
          <p>
            Once you begin using our services through your account we will keep
            records of your transactions and collect information about your
            other activities related to our services. We will not share or
            disclose Your Information with a third party without your consent.
          </p>
          <p>
            When submitting any of Your Information, we advise that you put in
            the correct details of your information, as any inaccurate
            information, you submit to us might limit your ability to use our
            service or may affect our ability to contact you. When you notice
            that the information you submit to us is inaccurate, kindly contact
            us support@appmartgroup.com to rectify your information.
          </p>
        </div>

        <div>
          <h5>Information We Collect From Web Application Visitors</h5>
          <p>
            We do collect your Personal Information when you visit the website.
            However, so we can monitor and improve our Web Application and
            services, we may collect non-Personal-identifiable information. We
            will not share or disclose this information with third parties
            except as a necessary part of providing our Web Application and
            services. We may use the information to target advertisements to
            you.
          </p>
          <p>
            By submitting your information to us, you give us your consent to
            use your information for the above purposes. When we decide other
            options of processing your data, we will inform you.
          </p>

          <h5>Legal Basis</h5>
          <p>
            The legal basis we rely on to process your personal information are:
          </p>
          <ul>
            <li>
              Your consent: when you consent to us collecting your personal
              information using our services.
            </li>
            <li>
              We have a contractual obligation: without your personal
              information, we cannot provide our services to you.
            </li>
            <li>
              We have a legal obligation: to ensure that we fully comply with
              all applicable financial legislation such as anti-money laundering
              and anti-terrorist financing laws, we must collect and store your
              personal information. We protect against fraud by verifying your
              identity with your personal information.
            </li>
          </ul>
          <h5>How We Use Personal Information:</h5>
          <ul>
            <li>
              Operate and maintain your account and provide you with access to
              the Website and use of the Apps and Services that you may request
              from time to time. Your email address and password are used to
              identify you when you sign into the Platform. Your device-IDs are
              used to ensure that you are in control of the devices that have
              access to your Subscription.
            </li>
            <li>
              Process your payment transactions (including authorization,
              clearing, chargebacks and other related dispute resolution
              activities.
            </li>
            <li>
              Seek your participation in surveys and conduct and analyze the
              results of those surveys if you choose to participate.
            </li>
            <li>Provide you with technical support.</li>
            <li>
              Respond to you about any comment or enquiry you have submitted.
            </li>
            <li>
              Prevent or take action against activities that are, or maybe, in
              breach of our Terms of service or applicable law.
            </li>
            <li>For identification and verification purposes.</li>
            <li>For marketing, sales, and promotional activities.</li>
            <li>
              For product development, to build higher quality and more useful
              services.
            </li>
            <li>
              For security purposes and other purposes stated in these policies.
            </li>
            <li>Verify the information that you provide with third parties.</li>
            <li>Know You as our Customer.</li>
            <li>
              For other purposes for which we provide specific notice at the
              time of collection.
            </li>
          </ul>
          <h5>Rights to Your Information</h5>
          <p>
            As a user, you have certain rights/control over the information you
            submit to us. You have the right to;
          </p>
          <ul>
            <li>access and confirm Your Information.</li>
            <li>
              withdraw your consent from processing Your Information (this does
              not affect already processed information).
            </li>
            <li>rectify or update any inaccurate or outdated information.</li>
            <li>know the purpose for processing Your Information.</li>
            <li>restrict the processing of Your Information.</li>
            <li>erase any information we hold about you.</li>
            <li>request for a copy of the information we keep about you.</li>
            <li>object/refuse Your Information for direct marketing.</li>
            <li>port your data, where feasible.</li>
          </ul>
          <p>
            When you make a request to exercise any of the above rights, we
            shall provide you with your personal information and other necessary
            information as requested by you. However, we reserve the right to
            charge you or refuse your request where we notice that your request
            is repetitive or excessive. Your rights to erasure of Your
            Information does not apply to legal necessaries like invoices, audit
            logs, subscription information, and Your Information archived on our
            backup systems which we shall securely isolate and protect the use
            of it from any further processing to the extent required by
            applicable law etc.
          </p>
          <h5>How We Share Personal Information</h5>
          <p>
            Information about our customers is an important part of our business
            and we are not in the business of selling our customers` personal
            information to others. All information shared with us are
            confidential. We share personal information only as described below
            and with AppMart.ng and the affiliates that AppMart controls that
            are either subject to this Privacy Policy or follow practices at
            least as protective as those described in this Privacy Policy.
          </p>
          <ul>
            <li>
              Third-party Service Providers: To provide you with these services
              we engage qualified third parties that assist us with different
              services which include financial institutions, payment processors
              verification. These Sub-processors set out below provide cloud
              hosting and storage services; content delivery and review
              services; assist in providing customer support; as well as
              incident tracking, response, diagnosis, and resolution services.
              These service providers include Nigeria Inter-bank Settlement
              System (NIBSS), Amazon Web Services, EApps. We work and operate
              with them to ensure that the services we provide you meet your
              demand and expectation. Our service providers are based in all
              parts of the world. Be rest assured that their access to Your
              Information is limited and they will not disclose Your
              Information.
            </li>
            <li>
              Business Transfers: As we continue to develop our business, we
              might sell or buy businesses or services. In such transactions,
              personal information generally is one of the transferred business
              assets but remains subject to the promises made in any
              pre-existing Privacy Policy (unless, of course, the individual
              consents otherwise). Also, in the unlikely event that AppMart or
              substantially all of its assets are acquired, your information
              will of course be one of the transferred assets.
            </li>
            <li>
              Protection of Us and Others: We release account and other personal
              information when we believe release is appropriate to comply with
              the law, enforce or apply our terms and other agreements, or
              protect the rights, property, or security of AppMart, our
              customers, or others. This includes exchanging information with
              other companies and organizations for fraud prevention and
              detection and credit risk reduction.
            </li>
            <li>
              Employee, agents, affiliates, and representatives: - We may
              disclose Your Information to our employees, representatives`
              agents, and affiliates provided that such third party shall take
              measures to keep the information confidential and abide by these
              terms of service.
            </li>
            <li>
              At Your Option: Other than as set out above, you will receive
              notice when personal information about you might be shared with
              third parties, and you will have an opportunity to choose not to
              share the information.
            </li>
          </ul>
          <h5>Profile Security</h5>
          <p>
            We require you to create an account which entails you setting up a
            profile on the AppMart Platform. You are solely responsible for
            maintaining confidentiality as it relates to information like
            authentication details of your account, access details of your
            account on the site, and your password. We advise that any device
            used to access this site should be kept secured to prevent
            unauthorized persons from having access to your card or account
            details, profile details, Password, and other information. We are
            not liable for any loss you suffer from your inability to prevent
            unauthorized persons from having access to your device, password,
            card or account details, and other information.
          </p>
          <h5>Location Of Information</h5>
          <p>
            AppMart is located in Nigeria, and some of our affiliate companies
            which are located in what forms part of the whitelist countries.
            Depending on the scope of your interactions with AppMart, your
            personal information may be stored in or accessed from any of these
            other countries, including Nigeria. Whenever we transfer personal
            information to other jurisdictions, we will ensure that the
            information is transferred in accordance with this Privacy Policy
            and as permitted by applicable data protection laws.
          </p>
          <h5>How We Protect Your Information</h5>
          <p>
            AppMart is committed to managing your Personal Information in line
            with global industry best practices. We protect your Personal
            Information using physical, technical, and administrative security
            measures to reduce the risks of loss, misuse, unauthorized access,
            disclosure, and alteration. We also use industry-standard Secure
            Socket Layer (SSL) encryption technology to safeguard your Personal
            Information. Other security safeguards include but are not limited
            to data encryption, firewalls, and physical access controls to our
            building and files and only granting access to your information to
            only employees who require it to fulfill their job responsibilities.
            When you use a password for any of your accounts, make sure you
            don't share it with anyone, and that the password is always kept
            confidential. All your key information is properly encrypted and
            secured, secure hosting via SSH via authorized accounts, Strong
            Client passwords combination, Role-based Access Control (RBAC), and
            audit trail of all users` activities. However, we do not guarantee
            100% security. We shall inform you where we notice any data breach.
          </p>
          <h5>Updates, Modifications and Amendments</h5>
          <p>
            We may need to update, modify or amend our privacy policy as our
            technology evolves. We reserve the right to make changes to this
            privacy policy at any time by giving notice to Users on this page.
            We advise that you check this page often, referring to the date of
            the last modification on the page. If a User objects to any of the
            changes to the Policy, the User must cease using this Site, or
            terminate the account in the event an account has been created.
          </p>
          <h5>Marketing Purposes</h5>
          <p>
            By providing you our services, you will receive newsletters,
            marketing information; road maps for this service and other
            notification which enables you to enjoy better services from us. You
            can decide not to receive the aforementioned notices by clicking the
            unsubscribe link on the messages. Kindly notify the means in which
            our marketing details may be sent to (SMS, phone calls, email)
            support@appmartgroup.com. You also have the ability to opt-out at
            any time from the use of your personal information for direct
            marketing purposes by sending us a request to opt-out of direct
            marketing. Based on the expectation of a successful engagement, you
            give us permission to use your name and logo in our marketing
            materials. You also give us permission to create a customer success
            story explaining the usage of the product and to provide customer
            reference calls upon request.
          </p>
          <h5>The Information That We Retain</h5>
          <p>
            Your Information will be kept online for you to access easily and
            will be stored for as long as the purposes for which they were
            collected. AppMart is statutorily obligated to retain the data you
            provide so as to comply with regulatory guidelines and laws
            applicable to us, our banking providers, and credit card processors.
            Therefore, even after closing your account, we will retain certain
            data to comply with these obligations. We may retain Your
            Information where there is a need for legal necessaries like
            invoices, audit logs, subscription information etc. We may delete
            Your Information after 2 years of inactivity from you. Inactivity is
            the day that marks the end of your last subscription to the service.
          </p>
          <h5>Controllers</h5>
          <p>
            You are the Controller of this service as you decide the purpose of
            your verification or processing of your data. We are processors of
            your captured data.
          </p>
          <h5>Government Regulations</h5>
          <p>
            You shall not export, re-export, transfer, or make available,
            whether directly or indirectly, any regulated item or information to
            anyone outside Nigeria in connection with this Agreement without
            first complying with all export control laws and regulations that
            may be imposed by the Nigerian Government and any country or
            organization of nations within whose jurisdiction you operate or do
            business.
          </p>
          <h5>Liability</h5>
          <p>
            Where you notice any data breach, kindly inform us immediately.
            Parties agree to settle any dispute relating to breach of data by
            negotiation. To the maximum extent permitted by applicable law, our
            liability for whatever cause, whether in contract or in tort or
            otherwise, will be limited to money damages and shall not exceed the
            amount corresponding to the last 2 months invoice to you. We shall
            not be liable for any claim if no written notice is received by us
            within one month after the occurrence of the breach of data. Where a
            jurisdiction does not provide for limitation of liabilities or
            exclusion of liability, in such case, our liability shall be the
            minimum permitted under such applicable law.
          </p>
          <h5>Cookies Policy</h5>
          <p>
            We use cookies to identify you as a User and make your user
            experience easier, customize our services, content, and advertising;
            help you ensure that your account security is not compromised,
            mitigate risk and prevent fraud; and promote trust and safety on our
            website. Cookies allow our servers to remember your account log-in
            information when you visit our website, IP addresses, date and time
            of visits, monitor web traffic, and prevent fraudulent activities.
            If your browser or browser add-on permits, you have the choice to
            disable cookies on our website, however, this may limit your ability
            to use our website.
          </p>
          <h5>Questions, Suggestions, And Complaints</h5>
          <p>
            For any privacy-related questions, suggestions, unresolved problems,
            or complaints you may contact us at: support@appmartgroup.com
          </p>
        </div>
      </div>
    </section>

    <section id="footer">
      <Footer />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },

  data() {
    return {
      scrollHeight: 0,
    };
  },

  mounted() {
    window.onscroll = function () {
      setFixedTop();
    };

    // Get the header
    const header = document.getElementById("header");

    // Get the offset position of the navbar
    const sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const setFixedTop = () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  },
};
</script>

<style>
.mt {
  margin-top: 0.6rem;
}

.about {
  height: 100%;
}

.heading {
  text-align: center;
  font-size: 2rem;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.content-wrap {
  margin-top: 2rem;
  text-align: justify;
}

.about::before {
  content: "";
  background-image: url("../assets/images/line-cc.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  pointer-events: none;
  opacity: 0.03;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #moreInfo {
    padding-left: 6rem;
    padding-right: 6rem;
  }
}
</style>
